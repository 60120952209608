<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat dense color="primary">
      <v-toolbar-title>Rekordy</v-toolbar-title>
    </v-toolbar>

    <!-- event -->
    <v-toolbar-title class="body-1 ml-3 mt-3 mr-3">Konkurencja</v-toolbar-title>
    <v-chip-group column v-model="event">
      <v-chip v-for="(e, index) in events" :key="e" outlined
              :color="index === event ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRecords(e, undefined)">
        <v-icon>{{ $utils.events.getEventIcon(e) }}</v-icon>
      </v-chip>
    </v-chip-group>

    <!-- category -->
    <v-toolbar-title class="body-1 ml-3 mt-3 mr-3">Kategoria</v-toolbar-title>
    <v-chip-group column v-model="category">
      <v-chip v-for="(c, index) in categories" :key="index" outlined
              :color="index === category ? 'primary' : ''"
              class="ma-1" style="padding-top: 2px; padding-left: 13px"
              @click="loadRecords(undefined, c)">
        {{ getCategoryName(c) }}
      </v-chip>
    </v-chip-group>

    <v-divider/>

    <!-- records -->
    <div v-if="state === 'ok'">
      <v-card v-for="(cat, i) in records" v-bind:key="i"
              elevation="0" class="ma-2">
        <v-card-title>
          <v-icon left color="primary">{{ '$event-' + cat.event }}</v-icon>
          {{ cat.title }}
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">Typ</th>
                <th class="text-left">Zawodnik</th>
                <th class="text-left">Wynik</th>
                <th class="text-left">Zawody</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(record, index) in cat.records" :key="index">
                <td class="text-left">{{ record[4] === 's' ? 'Najlepsze' : 'Średnia' }}</td>
                <td class="text-left">{{ record[1] }}</td>
                <td class="text-left">
                  {{ $utils.results.parseResult(record[5], $utils.events.EVENT_ICONS.indexOf(cat.event)) }}
                </td>
                <td class="text-left">{{ record[3] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>
    <!-- loading -->
    <v-skeleton-loader v-if="state === 'loading'" type="card"/>

    <!-- error -->
    <v-alert v-if="state === 'error'">
      <v-icon left color="error">mdi-alert-circle-outline</v-icon>
      Wystąpił nieoczekiwany błąd.
    </v-alert>

  </v-card>
</template>

<script>
export default {
  name: 'Records',
  data: () => ({
    state: 'loading',
    event: undefined, events: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    category: 0, categories: ['overall', '8', '10', '12', '14', '16', 'women', 'parents'],
    records: []
  }),
  created() {
    this.loadRecords(undefined, 'overall');
  },
  methods: {
    getCategoryName(category) {
      if (category === 'overall') return 'Open';
      if (category === '8') return 'Do lat 8';
      if (category === '10') return 'Do lat 10';
      if (category === '12') return 'Do lat 12';
      if (category === '14') return 'Do lat 14';
      if (category === '16') return 'Do lat 16';
      if (category === 'k') return 'Kobiety';
      if (category === 'r') return 'Rodzice';
      if (category === 'women') return 'Kobiety';
      if (category === 'parents') return 'Rodzice';
      return '';
    },
    loadRecords(event, category) {
      if (event != null) {
        this.category = undefined;
        event = this.$utils.events.EVENT_ICONS[event];
        // TODO change for development
        this.$http.get('https://rubiart.pawelduraj.ovh/data/records/' + event + '.csv?v=' + (new Date().getTime()).toString()).then((res) => {
          let records = res.data.split('\n');
          records.pop();
          for (let i = 0; i < records.length; i++)
            records[i] = records[i].trim().split(',');
          const CAT = ['parents', 'women', '16', '14', '12', '10', '8', 'overall'].reverse();
          let out = {};
          for (let i = 0; i < CAT.length; i++)
            out[CAT[i]] = {title: this.getCategoryName(CAT[i]), event: event, category: CAT[i], records: []};
          for (let i = 0; i < records.length; i++)
            out[records[i][6]].records.push(records[i]);
          out = Object.values(out).filter((item) => item.records.length !== 0).sort((x, y) => CAT.indexOf(x.category) - CAT.indexOf(y.category));
          this.records = out;
          this.state = 'ok';
        }).catch((e) => {
          this.state = 'error';
          console.log(e);
        });
      }
      if (category != null) {
        this.event = undefined;
        // TODO change for development
        this.$http.get('https://rubiart.pawelduraj.ovh/data/records/' + category + '.csv?v=' + (new Date().getTime()).toString()).then((res) => {
          let records = res.data.split('\n');
          records.pop();
          for (let i = 0; i < records.length; i++)
            records[i] = records[i].trim().split(',');
          const EVT = this.$utils.events.EVENT_ICONS;
          let out = {};
          for (let i = 0; i < EVT.length; i++)
            out[EVT[i]] = {title: this.$utils.events.getEventName(EVT.indexOf(EVT[i])), event: EVT[i], records: []};
          for (let i = 0; i < records.length; i++)
            out[records[i][6]].records.push(records[i]);
          out = Object.values(out).filter((item) => item.records.length !== 0).sort((x, y) => EVT.indexOf(x.event) - EVT.indexOf(y.event));
          this.records = out;
          this.state = 'ok';
        }).catch((e) => {
          this.state = 'error';
          console.log(e);
        });
      }
    }
  }
}
;
</script>
